<template>
  <div :class="['summary', { expanded: isExpanded }]">
    <!-- CONTENT -->
    <div class="summary_content">
      <div class="expander" @click="onExpander">
        <div
          :class="['expander_icon', isExpanded ? 'mod-isExpanded' : '']"
          v-html="icons.arrowRight"
        ></div>
      </div>

      <div class="overflowWrapper">
        <!-- CONTENT - back -->
        <div class="back">
          <div class="back_cta" @click="onBack">
            <div class="back_cta_icon" v-html="icons.arrowLeft"></div>
            <h5 class="back_cta_copy">zurück</h5>
          </div>
          <div class="back_placeholder"></div>
          <div v-if="!hidePrices" class="back_price">
            <p class="back_price_headline">Gesamtpreis</p>
            <h2 class="back_price_amount">{{ formatAsCurrency(totalPrice) }}</h2>
          </div>
        </div>

        <ProjectImgGallery class="projectImgGalleryComp_mobile" />

        <!-- CONTENT - header -->
        <div class="header">
          <div class="header_copy">
            <h3 v-html="summaryScreenCopy.heading"></h3>
            <p v-html="summaryScreenCopy.introductionText"></p>
          </div>
          <Hint
            v-if="summaryScreenCopy.tip && summaryScreenCopy.tip.text"
            :content="summaryScreenCopy.tip"
          />
          <ul class="header_list">
            <li v-if="!isSoftConfigurator" class="header_list_item" @click="onCopyLink">
              <div class="header_list_item_icon" v-html="icons.copyLink"></div>
              <h5 class="header_list_item_copy">Link kopieren</h5>
              <div v-if="linkIsCopying" class="header_list_item_loader">
                <div v-html="icons.spinner"></div>
              </div>
            </li>
            <li class="header_list_item" @click="onPDF">
              <div class="header_list_item_icon" v-html="icons.download"></div>
              <h5 class="header_list_item_copy">PDF herunterladen</h5>
              <div v-if="pdfIsLoading" class="header_list_item_loader">
                <div v-html="icons.spinner"></div>
              </div>
            </li>
          </ul>
        </div>

        <!-- CONTENT - overview -->
        <div class="overview">
          <div class="overview_headline">
            <div class="dottedBorder"></div>
            <h6 class="copy">Alles im Überblick</h6>
            <div class="dottedBorder"></div>
          </div>
          <div v-for="room in rooms" :key="room.slug" class="overview_wrapper">
            <ProductList :room="room" />
          </div>
        </div>
      </div>
    </div>

    <ProjectImgGallery class="projectImgGalleryComp_desktop" />
    <Confirmation v-if="!isSoftConfigurator" />

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import gsap from 'gsap';
import { formatAsCurrency } from '../utils/index.js';
import {
  generateConfigurationPdf,
  apiErrorReport,
  downloadFileFromUrl,
} from '@/service/apiService';
import ProductList from '@/components/ProductList_comp.vue';
import Confirmation from '@/components/Confirmation_comp.vue';
import ProjectImgGallery from '@/components/ProjectImgGallery_comp.vue';
import Hint from '@/components/Hint_comp.vue';

export default {
  name: 'SummaryView',
  inject: ['store'],
  components: {
    ProductList,
    Confirmation,
    ProjectImgGallery,
    Hint,
  },
  props: {
    roomSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      summaryScreenCopy: this.store.apiData.apartment.pages.summaryScreen,
      projectImages: this.store.apiData.project.images,
      rooms: this.store.apiData.rooms,
      isSoftConfigurator: this.store.apiData.apartment.configuration.isSoftConfigurator,
      isExpanded: false,
      confirmationIsActive: false,
      pdfIsLoading: false,
      linkIsCopying: false,
      icons: {
        arrowLeft: require('!html-loader!@/assets/svgs/arrowLeft.svg'),
        arrowRight: require('!html-loader!@/assets/svgs/arrowRight.svg'),
        download: require('!html-loader!@/assets/svgs/download.svg'),
        copyLink: require('!html-loader!@/assets/svgs/copyLink.svg'),
        cross: require('!html-loader!@/assets/svgs/cross.svg'),
        spinner: require('!html-loader!@/assets/svgs/spinner.svg'),
      },
    };
  },
  computed: {
    totalPrice() {
      return this.store.userSelectionData.basePrice
        ? this.store.userSelectionData.totalPrice - this.store.userSelectionData.basePrice
        : this.store.userSelectionData.totalPrice;
    },
    hidePrices() {
      return this.store.apiData.project.hidePrices;
    },
  },
  methods: {
    formatAsCurrency,
    onBack() {
      this.$router.push({ name: 'Planner', params: { roomSlug: this.roomSlug } });
    },

    async onPDF() {
      this.pdfIsLoading = true;
      await generateConfigurationPdf(this.$route.params.bpdId, this.store.userSelectionData)
        .then(_response => {
          downloadFileFromUrl(_response.data.url);
        })
        .catch(_error => {
          apiErrorReport(_error);
        })
        .finally(() => {
          this.pdfIsLoading = false;
        });
    },

    async onCopyLink() {
      const home = this.$router.resolve({ name: 'Home' });
      const url = window.location.origin + home.href;
      this.linkIsCopying = true;
      await navigator.clipboard.writeText(url);
      await new Promise(_resolve => setTimeout(_resolve, 500));
      this.linkIsCopying = false;
    },

    onExpander() {
      if (this.isExpanded) {
        this.isExpanded = false;
        gsap.to(document.querySelector('.summary_content'), {
          width: '480px',
          duration: 0.25,
        });
      } else {
        this.isExpanded = true;
        let expandWidth = 960;
        const cta = document.querySelector('.proposal');
        if (cta) {
          const expandMaxWidth = document.body.clientWidth - cta.clientWidth - 40;
          expandWidth = Math.min(expandWidth, expandMaxWidth);
        }
        gsap.to(document.querySelector('.summary_content'), {
          width: `${expandWidth}px`,
          duration: 0.25,
        });
      }
    },

    onProposal() {
      this.confirmationIsActive = true;
      this.$nextTick(() => {
        gsap.to(document.querySelector('.confirmation'), { opacity: 1, duration: 0.5 });
      });
    },

    onCloseConfirmation() {
      return new Promise(_resolve => {
        gsap.to(document.querySelector('.confirmation'), {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            this.confirmationIsActive = false;
            _resolve();
          },
        });
      });
    },

    async onConfirm() {
      await this.onCloseConfirmation();
      this.$router.push({ name: 'Success' });
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  overflow: hidden;

  &_content {
    position: relative;
    width: 480px;
    height: 100%;
    color: var(--White);
    background-color: var(--BetonLight);
    display: flex;
    flex-direction: column;
    max-width: 960px;

    .expander {
      top: 50%;
      transform: translateY(-50%);
      right: -25px;
      position: absolute;
      width: 25px;
      height: 64px;
      padding: 20px 10px 20px 1px;
      background-color: var(--BetonLight);
      border-radius: 0 10px 10px 0;
      z-index: 1;
      cursor: pointer;
      &_icon {
        width: 14px;
        height: 24px;
        color: var(--Asphalt);
        transition: all 0.5s;
        &.mod-isExpanded {
          transform: rotateY(180deg);
        }
      }
    }

    .overflowWrapper {
      overflow-y: auto;
      overflow-x: hidden;
      // @include hideScrollBar();
    }

    .projectImgGalleryComp_mobile {
      display: none;
    }

    .projectImgGalleryComp_desktop {
      display: block;
    }

    .back {
      position: sticky;
      z-index: 1;
      top: 0;
      height: 95px;
      background: var(--Asphalt);
      display: flex;
      box-shadow: 0px -1px 0px 0px var(--Asphalt);
      &_cta {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px dotted var(--BPDgray3);
        padding: 0 20px 0 20px;
        cursor: pointer;

        &_icon {
          width: 14px;
          height: 24px;
          color: var(--BPDgray2);
        }
        &_copy {
          margin: 0 0 0 10px;
          color: var(--White);
        }
      }
      &_placeholder {
        flex-grow: 1;
      }
      &_price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 20px;
        &_headline {
          color: var(--BPDgray2);
          font-weight: 600;
          margin: 0;
          font-size: 0.875em;
        }
        &_amount {
          margin: 0;
        }
      }
    }

    .header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 40px 30px 0 30px;
      &_copy {
        max-width: 420px;
        h3 {
          color: var(--Asphalt);
          margin: 0 0 10px 0;
        }
      }
      &_list {
        display: flex;
        margin-top: 40px;
        width: 100%;
        &_item {
          position: relative;
          max-height: 56px;
          background: var(--White);
          padding: 14px 10px 14px 10px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          border: 2px solid transparent;
          transition: all 0.25s;
          cursor: pointer;
          &_icon {
            width: 24px;
            color: var(--BPDgreen1);
            margin-right: 10px;
            object-fit: none;
          }
          &_copy {
            margin: 0;
          }
          @include ishoverSupported {
            &:hover {
              box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
            }
          }
          &:last-child:not(:first-child) {
            margin-left: 20px;
          }
          &_loader {
            padding: 14px 10px 14px 10px;
            height: 100%;
            position: absolute;
            background: rgba(255, 255, 255, 0.8);
            width: calc(100% - 20px); //-margin-left value
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            cursor: default;
          }
        }
      }
    }

    .overview {
      padding: 20px 30px 20px 30px;

      &_headline {
        display: flex;
        align-items: center;
        .dottedBorder {
          margin-top: 0;
        }
        .copy {
          flex: 1 0 auto;
          margin: 0 10px 0 10px;
          letter-spacing: 0.5px;
          color: var(--BPDgray2);
          text-align: center;
        }
      }
      &_wrapper {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  @include tabletAndPhone() {
    .summary_content {
      max-width: none;
      width: 100%;
      .header {
        max-width: none;
        &_copy {
          max-width: none;
        }
        &_list_item {
          flex-grow: 1;
        }
      }
      .overview_wrapper:last-child {
        margin-bottom: 90px;
      }
    }

    .projectImgGalleryComp_desktop,
    .expander {
      display: none;
    }

    .projectImgGalleryComp_mobile {
      display: block;
    }
  }
}

.summary.expanded {
  .summary_content {
    .header_list {
      flex-direction: column;
      width: auto;
      margin-top: 0;
      &_item:last-child {
        margin-left: 0;
      }
    }
  }
}

@include phone() {
  .summary_content {
    .header_list {
      flex-direction: column;
      &_item:last-child {
        margin-left: 0;
      }
    }
  }
}
</style>
